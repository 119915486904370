import { FC, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
    Switch,
    Route,
    useRouteMatch,
    useParams,
    withRouter,
    useLocation
} from "react-router-dom";

import { fetchCentresData, fetchCandidateList, fetchCandidateDocuments, fetchWMSStatus, setCandidateIdentifier, setSelectedCentre, fetchContextData, filterCandidateSetWise } from './../redux-building-blocks/actions';

import SyllabusList from './syllabus-list';
import CandidateList from './Candidates/candidate-list';
import RationaleDocument from './Candidates/rationale-document';
import AdditionalMaterial from './Candidates/additional-material';
import CandidateEvidence from './Candidates/candidate-evidence';

const Syllabus: FC = (props: any): JSX.Element => {
    const { id }: any = useParams();
    let { path } = useRouteMatch();
    const { pathname } = useLocation();

    const index = pathname.split('/').findIndex(eachElem => eachElem === 'syllabus');
    let syllabusId: string;
    let activeSetTab: any;
    if (index !== -1) {
        syllabusId = pathname.split('/')[index + 1];
        activeSetTab = pathname.split('/')[index + 2];
    }

    useEffect(() => {
        console.log('props.centreId:', props.centreId, id);
        if (id !== '' && !isEmpty(props.centresList)) {
            props.setSelectedCentre(id);
            props.fetchContextData(id);
        }

    }, [id, props.centresList]);

    useEffect(() => {
        if (!isEmpty(props.syllabusList) && syllabusId && props.activeTab) {
            props.fetchCandidateList(syllabusId);
            props.filterCandidateSetWise(activeSetTab)
            props.fetchCandidateDocuments(id, syllabusId);
            props.fetchWMSStatus(id, syllabusId);
        }
    }, [props.syllabusList, pathname.split('/').findIndex(eachElem => eachElem === 'syllabus')])
    return (
        <Switch>
            <Route exact path={`/centre/${id}`} component={SyllabusList} />
            <Route exact path={`${path}/syllabus/:syllabusNumber/:sampleNo`} component={CandidateList} />
            <Route path={`${path}/syllabus/:syllabusNumber/:sampleNo/candidate-evidence/:candidateId`} component={CandidateEvidence} />
            <Route path={`${path}/syllabus/:syllabusNumber/:sampleNo/rationale-document`} component={RationaleDocument} />
            <Route path={`${path}/syllabus/:syllabusNumber/:sampleNo/additional-document`} component={AdditionalMaterial} />
        </Switch>
    )
}

const mapStateToProps = (state: any) => {
    return {
        centreId: state.setContextData.selectedCentre,
        syllabusList: state.setContextData.syllabusList,
        centresList: state.setContextData.centresList,
        activeTab: state.setContextData.activeTab
    }
}

const mapDispatchToProps = {
    fetchCentresData,
    fetchCandidateList,
    fetchCandidateDocuments,
    fetchWMSStatus,
    setCandidateIdentifier,
    setSelectedCentre,
    fetchContextData,
    filterCandidateSetWise
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Syllabus));