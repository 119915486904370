import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { faChevronLeft, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {
    Container,
    Navbar,
    Row,
    Col,
    Button
} from 'reactstrap';
import APIService from '../../../services/apiService';
import { connect } from 'react-redux';

const Header = (props: any): JSX.Element => {
    const location: any = useLocation();
    const history = useHistory();
    const [centreID, setCentreID] = useState(props.currentCentreId);
    let { url } = useRouteMatch();
    const [currentLocation, setLocation] = useState({
        location: '',
        fromPath: ''
    });

    const logoutUser = () => {
        APIService.handleAccessErrorOrLogout(true);
    }

    const setNavigationPath = () => {
        let pathDetails = {
            label: '',
            url: ''
        };
        if (location.pathname === '/index.html'
            || location.pathname === '/'
            || !location.pathname) {
            pathDetails.label = ''
            pathDetails.url = ''
        }
        else if (location.pathname?.includes('centre')
            && !location.pathname?.includes('syllabus')
            && !location.pathname?.includes('rationale-document')
            && !location.pathname?.includes('additional-document')
            && !location.pathname?.includes('candidate-evidence')) {
            pathDetails.label = 'My centres'
            pathDetails.url = `${url}index.html`
        }
        else if (location.pathname?.includes('centre')
            && location.pathname?.includes('syllabus')
            && !location.pathname?.includes('rationale-document')
            && !location.pathname?.includes('additional-document')
            && !location.pathname?.includes('candidate-evidence') && props.currentCentreId) {
            pathDetails.label = 'Syllabus'
            pathDetails.url = `${url}centre/${props.currentCentreId}`
        }
        else if (location.pathname?.includes('candidate-evidence')
            || location.pathname?.includes('rationale-document')
            || location.pathname?.includes('additional-document')) {
            pathDetails.label = 'Candidate'
            pathDetails.url = `${url}centre/${props.currentCentreId}/syllabus/${props.selectedSyllabusNumber}/${props.activeTab}`
        }
        return pathDetails;
    }

    useEffect(() => {
        if ((props.currentCentreId)) {
            const { label, url } = setNavigationPath()
            setLocation({
                location: label,
                fromPath: url
            });
            (label==='') ? setCentreID('') : setCentreID(props.currentCentreId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, props.currentCentreId]);

    useEffect(() => {
        if ((props.selectedSyllabusNumber && props.activeTab)) {
            const { label, url } = setNavigationPath()
            setLocation({
                location: label,
                fromPath: url
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, props.selectedSyllabusNumber]);

    const navigateBack = () => {
        history.push({
            pathname: currentLocation.fromPath,
            state: {
                fromPath: location.pathname
            }
        })

    }

    return (
        <div className="nav-container">
            <Navbar color="faded" className="border-bottom py-3" light expand="md">
                <Container>
                    <Row className="w-100 align-items-center no-gutters">
                        <Col className="text-left font-weight-bold grayText">
                            {currentLocation.location ? <div className='cursor-pointer'  onClick={() => navigateBack()}>
                                <FontAwesomeIcon className="mr-1" icon={faChevronLeft} />
                                <span>{currentLocation.location}</span></div> : ''}
                        </Col>
                        <Col className="ml-5">
                            <img
                                src={logo}
                                width="200px"
                                className="d-inline-block align-top ml-5 mr-5"
                                alt="logo"
                            />
                        </Col>
                        <Col>
                            <div className="text-right font-weight-bold grayText">
                                <div><a className="help"  target="_blank" rel="noreferrer" href="https://training.cambridgeassessment.org.uk/course/view.php?id=373">Help and Contact</a></div>
                            </div>
                        </Col>
                        <Col className="text-right font-weight-bold">
                            <div>
                                <div>Moderator</div>
                                {(centreID!=='') &&  <div className="font-weight-bold grayText">Centre {centreID}</div>
                               }
                               <Button className="font-weight-bold" color="link" onClick={logoutUser}>Logout</Button>
                            </div>
                        </Col>
                        <FontAwesomeIcon className="ml-2" icon={faCaretDown} />
                    </Row>
                </Container>
            </Navbar>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        currentCentreId: state.setContextData?.selectedCentre,
        selectedSyllabusNumber: state.setContextData?.selectedSyllabus?.syllabusNumber,
        activeTab: state.setContextData?.activeTab
    }
}

export default connect(mapStateToProps, null)(Header);

