import {
    GET_CENTRES_DATA,
    GET_CENTRES_DATA_SUCCESS,
    GET_CENTRES_DATA_ERROR,
    GET_CONTEXT_DATA,
    GET_CONTEXT_DATA_ERROR,
    GET_CONTEXT_DATA_SUCCESS,
    GET_CANDIDATE_LIST,
    SET_CANDIDATE_IDENTIFIER,
    SET_CURRENT_CENTRE_ID,
    SET_CURRENT_USER_ID,
    TOGGLE_ALERT,
    FETCH_CANDIDATE_DOCUMENTS,
    FETCH_CANDIDATE_DOCUMENTS_ERROR,
    FETCH_CANDIDATE_DOCUMENTS_SUCCESS,
    DOWNLOAD_SAG_FILE,
    DOWNLOAD_SAG_FILE_SUCCESS,
    DOWNLOAD_SAG_FILE_ERROR,
    DOWNLOAD_WMS_FILE,
    DOWNLOAD_WMS_FILE_SUCCESS,
    DOWNLOAD_WMS_FILE_ERROR,
    UPDATE_RATIONAL_DOCUMENT,
    FETCH_ADDITIONAL_DOCUMENT_SUCCESS,
    UPDATE_ADDITIONAL_DOCUMENT,
    UPDATE_LOADER_SHOW_STATUS,
    FETCH_CANDIDATE_FOLDERS,
    FETCH_CANDIDATE_FOLDERS_FAILURE,
    FETCH_CANDIDATE_FOLDERS_SUCCESS,
    FETCH_EVIDENCE_FILES,
    FETCH_EVIDENCE_FILES_SUCCESS,
    FETCH_EVIDENCE_FILES_FAILURE,
    UPDATE_CANDIDATE_GRADE,
    UPDATE_CANDIDATE_GRADE_SUCCESS,
    UPDATE_CANDIDATE_GRADE_FAILURE,
    UPDATE_CANDIDATE_EVIDENCE_UPLOADED_DELETED_FILE,
    EVIDENCE_SUBMISSION,
    EVIDENCE_SUBMISSION_SUCCESS,
    EVIDENCE_SUBMISSION_ERROR,
    SET_SELECTED_CENTRE,
    FILTER_CANDIDATE_SETWISE,
    FETCH_WMS_STATUS,
    FETCH_WMS_STATUS_ERROR,
    FETCH_WMS_STATUS_SUCCESS,
    UPDATE_WMS_AFTER_UPLOAD,
    UPDATE_ACTIVE_TAB
} from './action-types';

export const fetchCentresData = () => ({
    type: GET_CENTRES_DATA
});

export const fetchCentresDataSuccess = (data: any) => ({
    type: GET_CENTRES_DATA_SUCCESS,
    payload: data
});

export const fetchCentresDataError = () => ({
    type: GET_CENTRES_DATA_ERROR
});
export const setSelectedCentre = (centreNumber: string) => ({
    type: SET_SELECTED_CENTRE,
    payload: centreNumber
})

export const fetchContextData = (centerId: any) => ({
    type: GET_CONTEXT_DATA,
    payload: centerId
});

export const fetchContextDataError = () => ({
    type: GET_CONTEXT_DATA_ERROR
});

export const setSyllabusList = (contextData: any) => ({
    type: GET_CONTEXT_DATA_SUCCESS,
    payload: contextData
});

export const fetchCandidateList = (syllabusId: any) => ({
    type: GET_CANDIDATE_LIST,
    syllabusId: syllabusId
});

export const filterCandidateSetWise = (setNumber: any) => ({
    type: FILTER_CANDIDATE_SETWISE,
    setNumber: setNumber
})

export const setCandidateIdentifier = (candidateIdentifier: any) => ({
    type: SET_CANDIDATE_IDENTIFIER,
    candidateIdentifier: candidateIdentifier
});

export const setCurrentCentreId = (currentCentreId: string) => ({
    type: SET_CURRENT_CENTRE_ID,
    payload: currentCentreId
});

export const setCurrentUserId = (currentUserId: string) => ({
    type: SET_CURRENT_USER_ID,
    payload: currentUserId
});

export const toggleAlertShowStatus = (alertInfo: any) => ({
    type: TOGGLE_ALERT,
    payload: {
        alertInfo
    }
});

export const fetchCandidateDocuments = (currentCentreId: string, syllabusId: string) => ({
    type: FETCH_CANDIDATE_DOCUMENTS,
    centreId: currentCentreId,
    syllabusId: syllabusId

});

export const fetchCandidateDocumentsSuccess = (data: any) => ({
    type: FETCH_CANDIDATE_DOCUMENTS_SUCCESS,
    payload: data
})

export const fetchCandidateDocumentsError = () => ({
    type: FETCH_CANDIDATE_DOCUMENTS_ERROR,
});

export const downloadSAGFile = (syllabusId: string, gradeFileName: string) => ({
    type: DOWNLOAD_SAG_FILE,
    payload: {
        filename: gradeFileName,
        syllabus: syllabusId
    }
});
export const downloadSAGFileSuccess = (url: string) => ({
    type: DOWNLOAD_SAG_FILE_SUCCESS,
    payload: url
});

export const downloadSAGFileFailure = () => ({
    type: DOWNLOAD_SAG_FILE_ERROR
})

export const downloadWMSFile = (fileLocation: string, fileName: string, fileType: string) => ({
    type: DOWNLOAD_WMS_FILE,
    payload: {
        'file-location': fileLocation,
        'file-name': fileName,
        'type': fileType
    }
});
export const downloadWMSFileSuccess = (url: string) => ({
    type: DOWNLOAD_WMS_FILE_SUCCESS,
    payload: url
});

export const downloadWMSFileFailure = () => ({
    type: DOWNLOAD_WMS_FILE_ERROR
});

export const updateWmsAfterUpload = (fileName: any) => ({
    type: UPDATE_WMS_AFTER_UPLOAD,
    fileName
});

export const updateRationalDocument = (fileName: string) => ({
    type: UPDATE_RATIONAL_DOCUMENT,
    payload: fileName
});

export const fetchAdditionalDocumentsSuccess = (additionalData: any) => ({
    type: FETCH_ADDITIONAL_DOCUMENT_SUCCESS,
    payload: additionalData
});

export const updateAdditionalDocument = (fileNames: Array<string>) => ({
    type: UPDATE_ADDITIONAL_DOCUMENT,
    payload: fileNames
});

export const toggleLoaderShowStatus = (status: boolean) => ({
    type: UPDATE_LOADER_SHOW_STATUS,
    payload: status
});

export const fetchCandidateFolderLocations = (data: any) => ({
    type: FETCH_CANDIDATE_FOLDERS,
    payload: data
});

export const fetchCandidateFolderLocationsSuccess = (data: any, candidateNumber: Number, basicStructForEvidence: any) => ({
    type: FETCH_CANDIDATE_FOLDERS_SUCCESS,
    payload: data,
    candidateNumber: candidateNumber,
    basicStructForEvidence
})

export const fetchCandidateFolderLocationsFailure = () => ({
    type: FETCH_CANDIDATE_FOLDERS_FAILURE
});

export const fetchEvidenceLevelFiles = (data: any) => ({
    type: FETCH_EVIDENCE_FILES,
    payload: data
});

export const fetchEvidenceLevelFilesSuccess = (data: any) => ({
    type: FETCH_EVIDENCE_FILES_SUCCESS,
    payload: data
});

export const fetchEvidenceLevelFilesFailure = () => ({
    type: FETCH_EVIDENCE_FILES_FAILURE
});

export const updateCandidateGrades = (data: any) => ({
    type: UPDATE_CANDIDATE_GRADE,
    payload: data
});

export const updateCandidateGradesSuccess = (data: any, payload: any) => ({
    type: UPDATE_CANDIDATE_GRADE_SUCCESS,
    payload: payload,
    status: data
});

export const updateCandidateGradesFailure = () => ({
    type: UPDATE_CANDIDATE_GRADE_FAILURE,
});

export const updateCandidateEvidenceDocument = (evidenceIndex: string, fileNames: Array<string>) => ({
    type: UPDATE_CANDIDATE_EVIDENCE_UPLOADED_DELETED_FILE,
    payload: { evidenceIndex, fileNames }
});

export const evidenceSubmission = (data: any) => ({
    type: EVIDENCE_SUBMISSION,
    payload: data
});
export const evidenceSubmissionSuccess = (data: any, payload: any) => ({
    type: EVIDENCE_SUBMISSION_SUCCESS,
    payload: payload,
    status: data
});

export const evidenceSubmissionFailure = () => ({
    type: EVIDENCE_SUBMISSION_ERROR
});

export const fetchWMSStatus = (currentCentreId: string, syllabusId: string) => ({
    type: FETCH_WMS_STATUS,
    centreId: currentCentreId,
    syllabusId: syllabusId

});

export const fetchWMSStatusSuccess = (data: any) => ({
    type: FETCH_WMS_STATUS_SUCCESS,
    payload: data
})

export const fetchWMSStatusError = () => ({
    type: FETCH_WMS_STATUS_ERROR,
});
export const setActiveTab = (currentTab:any) => ({
    type: UPDATE_ACTIVE_TAB,
    currentTab:currentTab
});