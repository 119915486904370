import React, { FC, useEffect ,useRef } from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useRouteMatch } from "react-router-dom";
import DownloadReportExcel from './Shared/downloadReport/download-report-excel';
import {
  Col,
  Row,
  CardBody,
  CardText,
  Card,
  Container
} from 'reactstrap';
import { fetchCandidateList,setActiveTab } from '../redux-building-blocks/actions';
import ApiService from '../services/apiService';
const SyllabusList: FC = (props: any): JSX.Element => {
  const headers=[
    {label:'Email',key:'email'},
    {label:'First Name',key:'firstname'},
    {label:'Last Name',key:'lastname'},
    {label:'Centre No.',key:'centreno'},
    {label:'Centre Name',key:'centrename'},
    {label:'Syllabus Code',key:'syllabuscode'},
    {label:'Syllabus Name',key:'syllabusname'},
    {label:'Sample',key:'Sample'},
    {label:'Centre Status',key:'centreno'},
    {label:'Centre Submission Date (QA1)',key:'Centre Submission Date (QA1)'},
    {label:'Outcome (QA1)',key:'Outcome (QA1)'},
    {label:'First WMS Status',key:'first-wms-status'},
    {label:'Submission Date (QA1) ',key:'Submission Date (QA1)'},
    {label:'Centre Submission Date (QA2)',key:'Centre Submission Date (QA2)'},
    {label:'Outcome (QA2)',key:'Outcome (QA2)'},
    {label:'second-wms-status',key:'second-wms-status'},
    {label:'Submission Date (QA2)',key:'Submission Date (QA2)'},
  ]


  useEffect(() => {
    console.log('props:', props.syllabusDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    /**
     * Whenever we navigate from syllabus list,the sample should always be 1st sample
     * Hence below props will dispatch 'UPDATE_ACTIVE_TAB' action and set the active tab to 1
     */
    props.setActiveTab(1)
  }, []);

  const history = useHistory();
  let { url } = useRouteMatch();
  const visitSyllabus = (syllabusNumber: string, index: number) => {
    console.log(syllabusNumber);
    history.push({
      pathname: `${url}/syllabus/${syllabusNumber}/${props.activeTab}`,
      state: {
        fromPath: props.location.pathname
      }
    });
  }

  const fetchReportData = () =>{
    return  ApiService.fetchCentreReport(props.selectedCentreNo);
  }

  return (

    <div className="">
      <div className='wrapper'>
        <Col>
          <div className="py-4">
            <h5 className="font-weight-bold centerName mb-3">{props.selectedcentreName}</h5>
            <div className="student-instruction">Select a syllabus to view the candidates entered and review and download candidate work.</div>
          </div>
        </Col>
      </div>
      {props?.syllabusDetails?.length >0 &&<div className="container mt-4">
        <div className="align-right">
            
        <DownloadReportExcel
              headers={headers}
              fileName='Report.csv'
              fetchReportData={fetchReportData}
            />
        </div>
      </div>}

      {props.syllabusDetails && props.syllabusDetails.map((value: any, index: number) => {
        return (
          <div className="container mt-4" key={index}>
            <Card className="card-row mb-2" >
              <CardBody className="card-body pxy-2">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="h5 font-weight-bold centerName">
                      {value.syllabusName}</h3>
                    <div className="font-weight-bold grayText">
                      {value.syllabusNumber}
                                    </div>
                  </Col>
                  <Col xs="auto" className="text-right">
                    <button className="btn startBtn" onClick={() => visitSyllabus(value.syllabusNumber, index)}>
                      <span className="font-weight-bold mr-3">VIEW</span>
                      <FontAwesomeIcon className="fileUpload" icon={faEye} />
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        );
      })}
      {(!props.syllabusDetails || !props.syllabusDetails.length) && <Card body className="text-center rounded">
        <CardText>No syllabus to display for this centre</CardText>
      </Card>
      }
    </div>
  )
}


{/* <div className='wrapper'>
        <Col>
          <div className="py-4">
            <h5 className="font-weight-bold centerName mb-3">Divaris Makaharis School 1847634</h5>
            <div className="student-instruction">Select a syllabus to see the candidates entered.</div>
          </div>
        </Col>
      </div> */}

const mapStateToProps = (state: any) => {
  return {
    syllabusDetails: state.setContextData?.syllabusList,
    selectedcentreName: state.setContextData?.selectedCentreName?.centrename,
    activeTab: state.setContextData?.activeTab,
    selectedCentreNo: state.setContextData?.selectedCentre
  }
}

const mapDispatchToProps = {
  fetchCandidateList,
  setActiveTab
}

export default connect(mapStateToProps, mapDispatchToProps)(SyllabusList);