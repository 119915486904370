export const GET_CENTRES_DATA = 'GET_CENTRES_DATA';
export const GET_CENTRES_DATA_SUCCESS = 'GET_CENTRES_DATA_SUCCESS';
export const GET_CENTRES_DATA_ERROR = 'GET_CENTRES_DATA_ERROR';
export const GET_CONTEXT_DATA = 'GET_CONTEXT_DATA';
export const GET_CONTEXT_DATA_SUCCESS = 'GET_CONTEXT_DATA_SUCCESS';
export const GET_CONTEXT_DATA_ERROR = 'GET_CONTEXT_DATA_ERROR';
export const GET_CANDIDATE_LIST = 'GET_CANDIDATE_LIST';
export const SET_SYLLABUS_LIST = 'SET_SYLLABUS_LIST';
export const SET_CURRENT_CENTRE_ID = 'SET_CURRENT_CENTRE_ID';
export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
export const SET_CANDIDATE_IDENTIFIER = 'SET_CANDIDATE_IDENTIFIER';
export const TOGGLE_ALERT = "TOGGLE_ALERT";
export const DOWNLOAD_SAG_FILE = "DOWNLOAD_SAG_FILE";
export const DOWNLOAD_SAG_FILE_SUCCESS = "DOWNLOAD_SAG_FILE_SUCCESS";
export const DOWNLOAD_SAG_FILE_ERROR = "DOWNLOAD_SAG_FILE_ERROR";
export const DOWNLOAD_WMS_FILE = "DOWNLOAD_WMS_FILE";
export const DOWNLOAD_WMS_FILE_SUCCESS = "DOWNLOAD_WMS_FILE_SUCCESS";
export const DOWNLOAD_WMS_FILE_ERROR = "DOWNLOAD_WMS_FILE_ERROR";
export const FETCH_CANDIDATE_DOCUMENTS = "FETCH_CANDIDATE_DOCUMENTS";
export const FETCH_CANDIDATE_DOCUMENTS_ERROR = "FETCH_CANDIDATE_DOCUMENTS_ERROR";
export const FETCH_CANDIDATE_DOCUMENTS_SUCCESS = "FETCH_CANDIDATE_DOCUMENTS_SUCCESS";
export const UPDATE_RATIONAL_DOCUMENT = "UPDATE_RATIONAL_DOCUMENT";
export const FETCH_ADDITIONAL_DOCUMENT_SUCCESS = "FETCH_ADDITIONAL_DOCUMENT_SUCCESS";
export const UPDATE_ADDITIONAL_DOCUMENT = "UPDATE_ADDITIONAL_DOCUMENT";
export const UPDATE_LOADER_SHOW_STATUS = "UPDATE_LOADER_SHOW_STATUS";
export const FETCH_CANDIDATE_FOLDERS = "FETCH_CANDIDATE_FOLDERS";
export const FETCH_CANDIDATE_FOLDERS_SUCCESS = "FETCH_CANDIDATE_FOLDERS_SUCCESS";
export const FETCH_CANDIDATE_FOLDERS_FAILURE = "FETCH_CANDIDATE_FOLDERS_FAILURE";
export const FETCH_EVIDENCE_FILES = "FETCH_EVIDENCE_FILES";
export const FETCH_EVIDENCE_FILES_SUCCESS = "FETCH_EVIDENCE_FILES_SUCCESS";
export const FETCH_EVIDENCE_FILES_FAILURE = "FETCH_EVIDENCE_FILES_FAILURE";
export const UPDATE_CANDIDATE_GRADE = "UPDATE_CANDIDATE_GRADE";
export const UPDATE_CANDIDATE_GRADE_SUCCESS = "UPDATE_CANDIDATE_GRADE_SUCCESS";
export const UPDATE_CANDIDATE_GRADE_FAILURE = "UPDATE_CANDIDATE_GRADE_FAILURE";
export const UPDATE_CANDIDATE_EVIDENCE_UPLOADED_DELETED_FILE = "UPDATE_CANDIDATE_EVIDENCE_UPLOADED_DELETED_FILE";
export const UPDATE_CURRENT_SYLLABUS_STATUS = "UPDATE_CURRENT_SYLLABUS_STATUS";
export const EVIDENCE_SUBMISSION = "SUBMIT_EVIDENCE";
export const EVIDENCE_SUBMISSION_SUCCESS = "SUBMIT_EVIDENCE_SUCCESS";
export const EVIDENCE_SUBMISSION_ERROR = "SUBMIT_EVIDENCE_ERROR";
export const SET_SELECTED_CENTRE = "SET_SELECTED_CENTRE";
export const FILTER_CANDIDATE_SETWISE = "FILTER_CANDIDATE_SETWISE";
export const FETCH_WMS_STATUS = "FETCH_WMS_STATUS";
export const FETCH_WMS_STATUS_ERROR = "FETCH_WMS_STATUS_ERROR";
export const FETCH_WMS_STATUS_SUCCESS = "FETCH_WMS_STATUS_SUCCESS";
export const UPDATE_WMS_AFTER_UPLOAD = "UPDATE_WMS_AFTER_UPLOAD";
export const UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB";
