import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faPaperPlane, faCloudUploadAlt, faClock, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  Col,
  Row,
  Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';
import { SupmitPopup } from '../Shared/Modal/submit-modal';
import Uploader from './../Shared/Uploader/uploader';
import { Constant } from './../../config/constant';
import _ from 'lodash';
import { filterCandidateSetWise, evidenceSubmission, toggleAlertShowStatus, downloadWMSFile, updateWmsAfterUpload, fetchCandidateDocuments,fetchWMSStatus } from '../../redux-building-blocks/actions';
import APIService from './../../services/apiService';
import { getCurrentSyllabusStatus } from './../../redux-building-blocks/selectors/util-selector';
import UploadModal from '../Shared/Modal/upload-modal';
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

interface ICandidateInstructions {
  centreId: string;
  centreName: string;
  syllabusNumber: string;
  syllabusName: string;
  candidateCount: any;
}

export const CandidateInstructions: FC<ICandidateInstructions> = (props: any): JSX.Element => {
  const history = useHistory();
  const [passBtnColor, setGreenColor] = useState(false);
  const [failBtnColor, setRedColor] = useState(false);
  const [showModal, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  // const [activeTab, setActiveTab] = useState('Sample ' + props.activeTab);
  const [uploaderProps, setUploaderProps] = useState({
    noOfMaxFile: 1,
    fileFormatAndSizeSupported: Constant.WMS_ALLOWED_FILE_TYPE_SIZE
  });
  const [uploadedFileList, setUploadedFileList] = useState<Array<string>>([]);
  const [candidateWmsDoc, setCandidateWmsDoc] = useState({
  });

  const [showModalButton, setShowModalButton] = useState(false);
  const [showUploadModalFlag, setShowUploadModalFlag] = useState(false);
  const [uploadModalProps, setUploadModalProps] = useState({
    centreName: '',
    syllabusName: '',
    syllabusNumber: '',
    candidateCount: '',
    selectedcentreNo: ''
  });

  let { url } = useRouteMatch();
  const evidenceSections = url.split('/');
  evidenceSections.pop();
  url = evidenceSections.join('/');

  useEffect(() => {
    setUploadModalProps({
      centreName: props.centreName,
      syllabusName: props.syllabusName,
      syllabusNumber: props.syllabusNumber,
      candidateCount: props.candidateCount,
      selectedcentreNo: props.selectedcentreNo
    })
  }, [props]);

  useEffect(() => {
    if (props.activeTab) {
      setActiveTab('Sample ' + props.activeTab);
    }
  }, [props.activeTab])



  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setGreenColor(false);
    setRedColor(false);
    setShow(true);
  }

  const consthandlePassBtn = () => {
    setGreenColor(!passBtnColor);
    if (failBtnColor === true) {
      setRedColor(false);
    }
  }

  const consthandleFailBtn = () => {
    setRedColor(!failBtnColor);
    if (passBtnColor === true) {
      setGreenColor(false);
    }
  }

  const getSampleStatus = () => {
    if ((passBtnColor === true) && (failBtnColor !== true)) {
      return "pass";
    }
    else if ((failBtnColor === true) && (passBtnColor !== true)) {
      return "fail";
    }
  }

  const onSubmitEvidence = () => {
    console.log("submitted");
    const sampleStatus = getSampleStatus();
    const evidenceSubmissionPayload = {
      "sample-id": props.activeTab.toString(),
      "sample-status": sampleStatus,
      testcenter: props.selectedcentreNo,
      syllabus: props.syllabusNumber
    };
    props.evidenceSubmission(evidenceSubmissionPayload);
    setShow(false);
  }

  const [secondSampleFlag, setSecondSampleFlag] = useState(false);

  const onTabSelect = (tab: any) => {
    /** While switiching the tab after submiting the second sample,
     * syllabusStatus was taking the old value,Hence even after submiting the 2nd sample submit button was enabled
     * To get the latest syllabusStatus we called the wms-status api
    */
    props.fetchWMSStatus(props.selectedcentreNo,props.selectedSyllabusId);
    console.log('active Tab:::', tab);
    const sampleNo = tab.split(' ')[1];
    history.push({
      pathname: `${url}/${sampleNo}`
    });
    setTimeout(()=>{
      setActiveTab(tab);
    },1000)
    

  }

  const downloadSAG = () => {
    console.log('download SAG report', props.sagFileInfo[0]);
    if (props.sagFileInfo[0].documentName !== 'NONE' && props.sagFileInfo[0].documentLocation !== 'NONE') {
      props.downloadWMSFile(props.sagFileInfo[0].documentLocation, props.sagFileInfo[0].documentName, "evidence")
    } else {
      props.toggleAlertShowStatus({
        isShowAlert: true,
        alertColor: 'info',
        alertMessage: `No SAG file present.`
      })
    }
  }

  const downloadAssessorsReport = () => {
    console.log('Download QA Report', props);
    if ((candidateWmsDoc as any)?.wmsUploadName !== 'NONE' && (candidateWmsDoc as any)?.wmsUploadName) {
      props.downloadWMSFile((candidateWmsDoc as any)?.wmsUploadLocation, (candidateWmsDoc as any)?.wmsUploadName, "wms")
    } else {
      props.toggleAlertShowStatus({
        isShowAlert: true,
        alertColor: 'info',
        alertMessage: `No QA Report present.`
      })
    }
  }

  useEffect(() => {
    if (activeTab) {
      props.filterCandidateSetWise(activeTab.split(' ')[1]);
    }
  }, [activeTab]);

  useEffect(() => {
    if (props.activeTab && props.candidateGenericWMSDocuments.length) {
      const currentWms = props.candidateGenericWMSDocuments.find((each: any) => each.setNumber === Number(props.activeTab))
      const displayModal = (currentWms?.wmsUploadName !== null) ? true : false;
      setShowModalButton(displayModal);
    }
  }, [props.activeTab, props.candidateGenericWMSDocuments])

  useEffect(() => {
    if (props.candidateGenericWMSDocuments.length && props.activeTab) {
      const wmsFile = props.candidateGenericWMSDocuments.find((each: any) => each.setNumber === props.activeTab);
      setCandidateWmsDoc(wmsFile);
    }
  }, [props.candidateGenericWMSDocuments, props.activeTab]);

  useEffect(() => {
    if (props.selectedSyllabus?.candidateDetails && props.syllabusList.length) {
      const index = props.syllabusList.findIndex((res: any) => res.syllabusNumber === props.selectedSyllabus.syllabusNumber)
      const syllabusElem = props.syllabusList[index];
      const setIndex = syllabusElem?.candidateDetails?.findIndex((response: any) => response.setNumber === 2);
      // const index = props.selectedSyllabus.candidateDetails?.findIndex((res: any) => res.setNumber === 2);
      const flag = (setIndex === -1) ? false : true;
      setSecondSampleFlag(flag);
    }
  }, [props.selectedSyllabus])

  useEffect(() => {
    setUploaderProps((prev) => ({ ...prev, fileList: uploadedFileList }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFileList]);

  const handleUploadOrDeleteSuccess = (currentRelatedFiles: Array<string>, type: string, fileName = '') => {
    setUploadedFileList(currentRelatedFiles);
    const fileNameWMS = (!_.isEmpty(currentRelatedFiles)) ? currentRelatedFiles[0] : 'NONE';
    props.updateWmsAfterUpload(fileNameWMS);
    const message = (type === 'UPLOAD') ? `The file ${fileNameWMS} will be scanned for Virus and only accepted if virus-free, else the file will be discarded.` :
      `${fileName} is deleted`;
    props.toggleAlertShowStatus({
      isShowAlert: true,
      alertColor: 'success',
      alertMessage: message
    });

    // Making this call to refresh file list as virus scan may discard uploaded file
    if (type === 'UPLOAD') {
      setTimeout(() => {
        props.fetchCandidateDocuments(props.selectedcentreNo, props.selectedSyllabusId);
      }, Constant.GET_CALL_TIME_AFTER_UPLOAD);
    }

  }

  const showWMSModalPopup = () => {
    console.log('modal in here', uploadModalProps, showUploadModalFlag);
    setShowUploadModalFlag(true);
  }

  const handleUploadOrDeleteError = (currentRelatedFiles: Array<string>, type: string) => {
    const message = (type === 'UPLOAD') ? `You can't upload ${currentRelatedFiles}, Due to network issue` :
      `You can't delete ${currentRelatedFiles}, Due to network issue`;
    props.toggleAlertShowStatus({
      isShowAlert: true,
      alertColor: 'danger',
      alertMessage: message
    })
  }

  const returnPresignedUploadURl = (filename: string) => {
    const payload = {
      filename,
      syllabus: props.selectedSyllabusId,
      testcenter: props.selectedcentreNo
    }
    return new Promise((resolve, reject) => {
      APIService.getPreSignedUrlForWmsFileUpload(payload).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  const handleFileDelete = (filename: string) => {
    return new Promise((resolve, reject) => {
      APIService.deleteRationalDoc(props.selectedSyllabusId, filename).then((response: any) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  const setUploadPopState = (currentState: any) => {
    setShowUploadModalFlag(currentState)
  }

  return (
    <>
      <Container fluid className='container-wrapper'>
        <div className='wrapper'>
          <Row>
            <Col>
              <p className='theme-label centre-head'>{props.centreName} - {props.syllabusName}</p>
              <p className='syllabus-subtext'><span className='igcse-label'>{props.syllabusNumber}</span> | <span className='candidate-number'>{props.candidateCount} {props.candidateCount === 1 ? 'candidate' : 'candidates'}</span></p>
            </Col>
            <Col className='align-right'>
              <button className='download-SAG' onClick={() => downloadSAG()}>
                <FontAwesomeIcon icon={faArrowDown} /><br />
                <span>Download SAG</span>
              </button>
            </Col>
          </Row>
        </div>
        <div className="container mt-3">
          <div className="mb-4 navSection">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ sampleSelectionTab: activeTab === 'Sample 1' })}
                  onClick={() => onTabSelect("Sample 1")}>
                  Sample 1
                </NavLink>
              </NavItem>
              {secondSampleFlag ? <NavItem>
                <NavLink
                  className={classnames({ sampleSelectionTab: activeTab === 'Sample 2' })}
                  onClick={() => onTabSelect("Sample 2")}>
                  Sample 2
                </NavLink>
              </NavItem> : ''}
            </Nav>
          </div>
          <div className='align-right'>
            <button className='download-SAG' onClick={() => downloadAssessorsReport()}>
              <FontAwesomeIcon icon={faArrowDown} /><br />
              <span>Download QA Report</span>
            </button>
            {showModalButton ? <button className='upload-AR' onClick={() => showWMSModalPopup()} disabled={props.isSubmited}>
              <FontAwesomeIcon icon={faCloudUploadAlt} /><br />
              <span>Upload QA Report</span>
            </button> :
              <Uploader {...uploaderProps}
                successCallback={handleUploadOrDeleteSuccess}
                getPreSignedUploadURl={returnPresignedUploadURl}
                errorCallback={handleUploadOrDeleteError}
                fileDeleteHandler={handleFileDelete} />
            }
            {/* <Uploader {...uploaderProps}
                successCallback={handleUploadOrDeleteSuccess}
                getPreSignedUploadURl={returnPresignedUploadURl}
                errorCallback={handleUploadOrDeleteError}
                fileDeleteHandler={handleFileDelete} /> */}

            <button className={`submit-all ${(!showModalButton) ? `marginFix` : ``}`} onClick={() => handleShow()} disabled={props.isSubmited}>
              <FontAwesomeIcon icon={faPaperPlane} /><br />
              <span>Submit form</span></button>
          </div>
          <Row>
            <Col>
              {props.isSubmited && (
                <div className="bold-text">This is a view only mode</div>
              )}
              <div className="student-instruction">Select a candidate to view their evidence files and grades. You can also upload a QA Report by selecting 'Upload QA Report'</div>
            </Col>
            <Col className='align-right pt-4'>
              {/* <p>QA Report is not uploaded</p> */}
              {(candidateWmsDoc as any)?.wmsUploadName ? 'Assessors Report : ' + (candidateWmsDoc as any).wmsUploadName : ' QA Report is not uploaded'}
            </Col>
          </Row>
        </div>
      </Container>
      {showModal && (
        <SupmitPopup
          className="bg-white"
          isOpen={showModal}>
          <Row className="pb-3">
            <Col>
              <div className="font-weight-bold centerName">{props.centreName} {props.centreId} - {props.syllabusName} </div>
              <div className="font-weight-bold grayText"><span className='igcse-label'>{props.syllabusNumber}</span> | <span className='candidate-number'>{props.candidateCount} {props.candidateCount === 1 ? 'candidate' : 'candidates'}</span></div>
            </Col>
            <FontAwesomeIcon icon={faTimes} className="grayText cancelModal" onClick={() => handleClose()} />
          </Row>
          <p className="font-weight-bold pt-2">Submit form</p>
          <p>
            Before submitting, select 'Pass' if the centre passed the quality assurance or select 'Fail' if the centre failed the quality assurance.
          </p>
          <Row className="px-2 pb-4">
            <button className={(passBtnColor === false) ? "btn modalBtn font-weight-bold mr-2" : "btn passBtn font-weight-bold mr-2"} onClick={() => consthandlePassBtn()}>PASS</button>
            <button className={(failBtnColor === false) ? "btn modalBtn font-weight-bold" : "btn failBtn font-weight-bold mr-2"} onClick={() => consthandleFailBtn()}>FAIL</button>
          </Row>
          <Row>
            <button className="btn ml-1" onClick={() => handleClose()}>
              <span className="font-weight-bold grayText">Cancel</span>
            </button>
            <button className="btn startBtn ml-3" disabled={(passBtnColor === false) && (failBtnColor == false)} onClick={() => onSubmitEvidence()}>
              <span className="font-weight-bold">Submit form</span>
            </button>
          </Row>
        </SupmitPopup>
      )}
      {showUploadModalFlag
        && <UploadModal {...uploadModalProps}
          showModalFlag={showUploadModalFlag}
          setUploadModalState={setUploadPopState}
          successCallback={handleUploadOrDeleteSuccess}
          getPreSignedUploadURl={returnPresignedUploadURl}
          errorCallback={handleUploadOrDeleteError}
          fileDeleteHandler={handleFileDelete} />}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    wmsUploadLocation: state.setContextData.candidateGenericWMSDocuments?.wmsUploadLocation,
    wmsUploadName: state.setContextData.candidateGenericWMSDocuments?.wmsUploadName,
    sagFileInfo: state.setContextData.SAGFileInfo,
    selectedSyllabus: state.setContextData?.selectedSyllabus,
    selectedcentreNo: state.setContextData?.selectedCentreName?.centreno,
    activeTab: state.setContextData.activeTab,
    selectedSyllabusId: state.setContextData.selectedSyllabus?.syllabusNumber,
    candidateGenericWMSDocuments: state.setContextData.candidateGenericWMSDocuments,
    isSubmited: getCurrentSyllabusStatus(state),
    syllabusList: state.setContextData?.syllabusList
  }
}

const mapDispatchToProps = {
  filterCandidateSetWise,
  evidenceSubmission,
  downloadWMSFile,
  updateWmsAfterUpload,
  toggleAlertShowStatus,
  fetchCandidateDocuments,
  fetchWMSStatus
}


export default connect(mapStateToProps, mapDispatchToProps)(CandidateInstructions);

