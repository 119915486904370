import React, { FC,  useState, useRef } from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import {  toggleAlertShowStatus } from '../../../redux-building-blocks/actions';
import { CSVLink } from 'react-csv';
import moment from 'moment';

interface DownloadReportExcelProps{
    headers:{label:string,key:any}[],
    fileName:string,
    fetchReportData:any
}


const DownloadReportExcel: FC<DownloadReportExcelProps> = (props: any): JSX.Element => {
    const [data, setData] = useState([]);

    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    
    
    const getValueForDateTypeField = (data:any,key:string) => {
        const value = moment(data[key]).format('DD/MM/YYYY, h:mm:ss');
        if(value === 'Invalid date'){
            return data[key];
        } else{
            return value;
        }
    }
    
    const downloadReportHandler = () => {

        props.fetchReportData().then((res: any) => {
            
            let reportData = res.data.reports;
            reportData = reportData.map((data : any)=>{
                return{
                    ...data,
                    'Centre Submission Date (QA1)':getValueForDateTypeField(data,'Centre Submission Date (QA1)'),
                    'Centre Submission Date (QA2)':getValueForDateTypeField(data,'Centre Submission Date (QA2)'),
                    'Submission Date (QA1)':getValueForDateTypeField(data,'Submission Date (QA1)'),
                    'Submission Date (QA2)':getValueForDateTypeField(data,'Submission Date (QA2)'),

                }
            })
            setData(reportData);
            csvLinkRef?.current?.link.click();
        }).catch((err: any) => {
            const errMessage = err?.response?.message ? err?.response?.message : 'Error';
            props.toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'danger',
                alertMessage: errMessage
            })
        })
        

    }


    return (
        <>
            <button className="download-SAG" onClick={downloadReportHandler}>
                <FontAwesomeIcon icon={faArrowDown} /><br />
                <span>Download Report</span>
            </button>
            <CSVLink
                headers={props.headers}
                data={data}
                filename={props.fileName}
                ref={csvLinkRef}
            />

        </>
    )
}



const mapDispatchToProps = {
    toggleAlertShowStatus
}

export default connect(null, mapDispatchToProps)(DownloadReportExcel);